import { Map as olMap } from 'ol';

const MIN_ZOOM = 4;
const STATE_VALIDITY = 48 * 60 * 60 * 1000;

let UserId: number = -1;

class MapStateSession {
	extents: number[]
	bgMap: number
	userId: number
	createdOn: number
}


export function SaveMapState(map: olMap, bgMap: number) {
	let userStates: Map<number, MapStateSession> = new Map<number, MapStateSession>();

	try {
		userStates = new Map(JSON.parse(sessionStorage.getItem("mapState")));
	}
	catch {
	}

	if (userStates == null || !("set" in userStates)) userStates = new Map<number, MapStateSession>();

	let state = new MapStateSession();
	state.extents = map.getView().calculateExtent();
	state.bgMap = bgMap;
	state.userId = UserId;
	state.createdOn = Date.now();

	userStates.set(UserId, state);
	sessionStorage.setItem("mapState", JSON.stringify(Array.from(userStates)));
}


function getState(userId: number): MapStateSession {
	let userStates: Map<number, MapStateSession> = new Map<number, MapStateSession>();

	try {
		userStates = new Map(JSON.parse(sessionStorage.getItem("mapState")));
	}
	catch {

	}

	if (userStates == null || !("set" in userStates)) return null;

	if (!("has" in userStates)) return null;
	if (userStates.has(userId)) {
		let state = userStates.get(userId);
		if (Date.now() - state.createdOn < STATE_VALIDITY) {
			return state;
		}
	}

	return null;
}


export function LoadMapExtents(userId: number, map: olMap, defaultExtents: string) {
	UserId = userId;

	let state = getState(userId);
	if (state != null) {
		map.getView().fit(state.extents);
		return;
	}

	if (defaultExtents) {
		map.getView().fit(JSON.parse(defaultExtents));
	}
	else {
		//map.getView().fit([-14796983, 2557750, -6764050, 6144594]); //US Extents
		map.getView().fit([9493280, 3203656, 9503460, 3217779]); //Kathmandu Extents
	}

	map.getView().setMinZoom(MIN_ZOOM);

}

export function GetBgMapState(userId: number, defaultBgMap: number): number {
	UserId = userId;

	let state = getState(userId);
	if (state != null) {
		return state.bgMap;
	}
	return defaultBgMap;
}